import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CommonContext } from "../Contexts/CommonStates";
import { allBooks } from "../Databases/Database";
import loader from "../assets/loading.gif";
import { navtxt } from "./text";
import axios from "axios";
import config from "./config";

const BookInfo = () => {
  const lang = localStorage.getItem("lang");
  const navigate = useNavigate();
  const {
    currentSelectedCategory,
    showBooksFun,
    showBooks2,
    selectedInfo,
    setSelectedInfo,
    allBooks,
  } = useContext(CommonContext);
  // const [data, setData] = useState();

  // useEffect(() => {
  //   const uid = localStorage.getItem("uid");
  //   fetchData(uid);
  // }, []);

  // async function fetchData(uid) {
  //   const response = await axios({
  //     method: "post",
  //     url: "https://digitalwebsandboxv1.bngrenew.com/api/dataApi",
  //     data: {
  //       uid: uid,
  //       msisdn: 701701701,
  //       mcc: 611,
  //       mnc: "04",
  //     },
  //     headers: config.get("headers"),
  //   });
  //   setData(response.data);
  // }

  // console.log("selected info----->", selectedInfo);

  const showMoreBooks2 = () => {
    showBooksFun((prevValue) => prevValue + 4);
  };

  const sorryTexts = [
    {
      en: "Sorry, no books found! :(",
      fa: "ببخشيد، هيچ کتابي پيدا نشد",
      ps: "بخښنه غواړم، هیڅ کتابونه نه دی موندل شوی",
    },
  ];

  // const reqiuredcategoryName = allBooks && allBooks.length>0 && allBooks[0].categoryName;
  // const result = allBooks.filter(
  //   (data) => data.categoryName == reqiuredcategoryName
  // );
  // console.log(result, reqiuredcategoryName, "result------");
  // console.log(allBooks, "result------");

  useEffect(() => {
    console.log("selectedInfo", selectedInfo);
  }, [selectedInfo]);
  return (
    <div className="max-w-screen">
      <div className="flex flex-wrap justify-center md:px-40 px-4">
        {selectedInfo && selectedInfo.length > 0 ? (
          selectedInfo.slice(0, showBooks2).map((item) => {
            // console.log(selectedInfo, "currentSelectedctegory");
            // if (currentSelectedCategory === "school-books") {
            //   return (
            //     <div className="pb-1">
            //       <img
            //         className="h-48 md:h-82 w-36 md:w-64  rounded-xl  cursor-pointer mx-2 md:mx-5  py-2"
            //         alt="/"
            //         src={item.imgUrl}
            //         onClick={() => navigate(`/book/?id=${item.id}`)}
            //       />
            //     </div>
            //   );
            // } else {
            return (
              <div className="pb-1">
                <img
                  className="h-48 md:h-82 w-36 md:w-64  rounded-xl  cursor-pointer mx-2 md:mx-5  py-2"
                  alt="/"
                  src={item.imgUrl}
                  onClick={() => navigate(`/book/?id=${item.id}`)}
                />
                 <div className='mx-4 w-64 font-bold text-xl mb-6 flex justify-center'>
                            <label className='text-gray-800'>
                                {item.bookName}
                            </label>
                        </div>
              </div>
            );
            // }
          })
        ) : (
          <div className="flex justify-center items-center h-24">
            {console.log("entered in sorry")}
            <p className="text-gray-800 font-semibold">
              {navtxt.comingsoon[lang]}
            </p>
          </div>

          // <div className="flex justify-center mt-5">
          //   <img src={loader} className="z-10"></img>
          // </div>
        )}
      </div>

      {/* {currentSelectedCategory === "All Books" && (
        <div className="flex justify-center pb-8">
          {showBooks2 < data?.allBooks.length && (
            <button
              className=" border rounded-md text-white font-roboto font-semibold bg-yellow w-[70%] md:w-[25%] md:h-10 h-8"
              onClick={showMoreBooks2}
            >
              {navtxt.viewmore[lang]}
            </button>
          )}
        </div>
      )} */}
      {selectedInfo && selectedInfo.length > 4 && (
        <div className="flex justify-center pb-8">
          {showBooks2 < selectedInfo.length && (
            <button
              className=" border rounded-md text-white font-roboto mt-2 font-semibold bg-yellow w-[80%] md:w-[25%] md:h-10 h-8"
              onClick={showMoreBooks2}
            >
              {navtxt.viewmore[lang]}
            </button>
          )}
        </div>
      )}
    </div>
  );
};
export default BookInfo;
