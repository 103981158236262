import React, { useState, useEffect, useRef, useContext } from "react";
import Navbar from "./Navbar";
// import { PDFReader } from "reactjs-pdf-reader";
import { Document, Page, pdfjs } from "react-pdf";
import "./TheBook.css";
import * as RiIcons from "react-icons/ri";
// import Logo from "../assets/Logo.png";
import arrow3 from "../assets/arrow3.png";
import { Link, useLocation } from "react-router-dom";
import queryString from "query-string";
import { allBooks } from "../Databases/Database";
import Footer from "./Footer";
import { Loading } from "./Loading";
// import image from "../assets/bgmobile.png"
// import image2 from "../assets/bgweb.png"
import loader from "../assets/loading.gif";
import { chngimg } from "./imagechng.js";
import { CommonContext } from "../Contexts/CommonStates";
// import axios from "axios";
import config from "./config";
import axios from "axios";
import { navtxt } from "./text.js";

export const TheBook = () => {
  // const demoBase = "https://risdemo.bngrenew.com";
  // const liveBase = "http://www.mtnknowledgebud.com";

  const { setIsCategoryOpen, setIsTypeOpen, setIsLangOpen } =
    useContext(CommonContext);
  // const [loading, setLoading] = useState(false);
  const { search } = useLocation();
  const { id } = queryString.parse(search);
  const scollToRef = useRef();
  const pageNoRefs = useRef([]);
  const [numPages, setNumPages] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageNumbers, setPageNumbers] = useState([]);
  const lang = localStorage.getItem("lang");
  const [data, setData] = useState();
  let file;

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
    // console.log("pdfjs.version", pdfjs.version);
  });

  useEffect(() => {
    const uid = localStorage.getItem("uid");
    fetchData(uid);
  }, []);

  async function fetchData(uid) {
    const response = await axios({
      method: "GET",
      url: "https://digitalweb.bngrenew.com/api/dataApi",
      mode: "no-cors",
      data: {
        uid: uid,
        msisdn: 701701701,
        mcc: 611,
        mnc: "04",
      },
      headers: config.get("headers"),
    });
    setData(response.data);
    console.log(
      "api data response from fetchData function  -----> ",
      response.data
    );
  }

  useEffect(() => {
    let pgNos = [];
    for (let i = 0; i < Math.ceil(numPages); i++) {
      pgNos.push(i + 1);
    }
    setPageNumbers(pgNos);
  }, [numPages]);

  // eslint-disable-next-line eqeqeq
  const givenBookJsonInArr = data?.allBooks.filter((item) => item.id == id);
  console.log("givenBookJsonInArr", givenBookJsonInArr);

  useEffect(() => {
    if (pageNumbers) {
      pageNoRefs.current = pageNoRefs.current.slice(0, pageNumbers.length);
      // console.log("pageNoRefs.current", pageNoRefs.current[0]);
    }
  }, [pageNumbers]);

  const prevBtn = () => {
    return (
      <div>
        <p
          className="bg-[#f8ce46] mt-4 text-white px-2 h-10 py-2 border border-white select-none rounded-lg shadow-md ml-1 cursor-pointer hover:shadow-lg transition ease-out duration-200"
          onClick={() => {
            if (pageNumbers && pageNumbers.length > 0) {
              if (pageNumber !== pageNumbers[0]) {
                setPageNumber(pageNumber - 1);
                pageNoRefs.current[pageNumber - 2].scrollIntoView();
              }
            }
          }}
        >
          <RiIcons.RiArrowLeftSLine
            //className="animate-pulse-slow "
            size={25}
            //color={pageNumber === pageNumbers[0] ? `#a0b3d9` : "#4476da"}
            color={"#fff"}
          />
        </p>
      </div>
    );
  };

  const nextBtn = () => {
    return (
      <div>
        <p
          className="bg-[#f8ce46] mt-4 text-white px-2 h-10 py-2 border border-white select-none rounded-lg shadow-md ml-3 cursor-pointer hover:shadow-lg transition ease-out duration-200"
          onClick={() => {
            // eslint-disable-next-line no-lone-blocks
            {
              /* scollToRef.current.scrollLeft +=
              pageNumber < 12
                ? pageNumber * 8
                : pageNumber > 12 && pageNumber < 45
                ? pageNumber * 2
                : pageNumber; */
            }
            // eslint-disable-next-line no-lone-blocks
            {
              /* scollToRef.current.scrollLeft += 40; */
            }
            if (pageNumbers && pageNumbers.length > 0) {
              if (pageNumber !== pageNumbers[pageNumbers.length]) {
                setPageNumber(pageNumber + 1);
                pageNoRefs.current[pageNumber].scrollIntoView();
              }
            }
          }}
        >
          <RiIcons.RiArrowRightSLine
            //className="animate-pulse-slow "
            size={25}
            //color={pageNumber === pageNumbers[0] ? `#a0b3d9` : "#4476da"}
            color={"#fff"}
          />
        </p>
      </div>
    );
  };

  const paginationComponent = () => {
    return (
      <div className="flex justify-center">
        <div className="bg-[#f8ce46] w-[300px] md:w-[850px] flex justify-center rounded-b-[25px] shadow-lg md:mb-20">
          {prevBtn()}
          <div
            className="flex flex-row overflow-x-scroll scrollHide w-1/2 bg-[#f8ce46] p-2 pb-4 my-2"
            ref={scollToRef}
          >
            {pageNumbers &&
              pageNumbers.length > 0 &&
              pageNumbers.map((item, idx) => (
                <p
                  id={idx}
                  className={`${
                    item === pageNumber
                      ? "bg-white text-[#f8ce46]"
                      : "bg-[#f8ce46] text-white"
                  } px-4 py-2 select-none rounded-lg shadow-md ml-1 cursor-pointer hover:shadow-lg transition ease-out duration-200`}
                  onClick={() => {
                    setPageNumber(item);
                  }}
                  ref={(el) => {
                    pageNoRefs.current[idx] = el;
                  }}
                >
                  {item}
                </p>
              ))}
          </div>
          {nextBtn()}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="invisible md:visible">
        <Navbar />
      </div>

      <div className="flex absolute justify-start md:hidden top-0 w-screen bg-yellow">
        <Link to="/home">
          <img className="h-4 m-4" alt="/" src={arrow3} />
        </Link>
        <Link to="/home">
        <img
          className="h-10 w-56 ml-5 rounded-full m-1 cursor-pointer"
          alt="/"
          src={chngimg.img2[lang]}
          onClick={() => {}}
        /></Link>
      </div>
      {/* <PDFReader
            url={theBOOK} //DummyPDF
            page={pageNumber}
            //showAllPage={true}
            onDocumentComplete={(e) => {
              console.log("e", e);
              setNumPages(e);
            }}
            scale={0.8}
            isShowHeader={true}
            isShowFooter={true}
            style={{ color: "red" }}
          /> */}
      <div
        onClick={() => {
          setIsCategoryOpen(false);
          setIsTypeOpen(false);
          setIsLangOpen(false);
        }}
      >
        <div>
          <div className="flex justify-center md:mt-12 mt-16 ">
            {console.log(
              givenBookJsonInArr?.[0]?.bookUrl,
              "givenBookJsonInArr[0].bookUrl --------->"
            )}

            {/* <iframe src="http://www.mtnknowledgebud.com/pdfs/around-the-world-in-80-days.pdf" width="100%" height="500px"> */}
            <Document
              // file={`${demoBase}/pdfs/${
              //   givenBookJsonInArr && givenBookJsonInArr[0].fileName
              // }`}
              // file={`${liveBase}/pdfs/${
              // givenBookJsonInArr && givenBookJsonInArr[0].fileName
              // }`}
              file={givenBookJsonInArr && givenBookJsonInArr[0].bookUrl}
              // file="http://s29.q4cdn.com/175625835/files/doc_downloads/test.pdf"
              // file="http://www.pdf995.com/samples/pdf.pdf"
              onLoadSuccess={(e) => {
                //console.log("LOADED", e._pdfInfo.numPages);
                setNumPages(e._pdfInfo.numPages);
              }}
              // file="http://islamic.s3.ap-south-1.amazonaws.com/mtnedu/pdfs/a-journey-to-the-centre-of-the-earth.pdf"

              error={navtxt.comingsoon [lang]}
              // className=" w-[80vw] mt-4"
              className="mt-10 w-[300px] h-[200px] md:w-[500px] "
              loading={Loading}
              noData={Loading}
            >
              {console.log("file...", file)}
              <div className="flex justify-center">
                <Page
                  pageNumber={pageNumber}
                  wrap
                  className={"h-full w-fit"}
                  renderAnnotationLayer={false}
                  //canvasBackground="#fff"
                  loading={() => {
                    return (
                      <div className="bg-white">
                        <img src={loader} alt="/" />
                      </div>
                    );
                  }}
                  object-fit="fill"
                  style={{
                    flexDirection: "row",
                    backgroundColor: "#fff",
                    width: "100%",
                    orientation: "portrait",
                  }}
                  width={300}
                  height={100}
                />
              </div>

              {paginationComponent()}
            </Document>
            {/* </iframe> */}
          </div>
        </div>
        <div className="invisible md:visible">
          <Footer />
        </div>
      </div>
    </div>
  );
};
