import ReactPlayer from "react-player";
// import { Container } from "@material-ui/core";
import { useState, useRef, useEffect, useContext, Fragment } from "react";
import { formatTime } from "./format";
import Control from "./Video-Controls/Control";
import Navbar from "./Navbar";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { allVideos } from "../Databases/Database";
import arrow3 from "../assets/arrow3.png";
import { Link } from "react-router-dom";
import { chngimg } from "./imagechng.js";
import { CommonContext } from "../Contexts/CommonStates";
import loader from "../assets/loading.gif";
import axios from "axios";
import config from "./config";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

let count = 0;
export const TheVideo = () => {
  const videoPlayerRef = useRef(null);
  const controlRef = useRef(null);
  const { search } = useLocation();
  const { id } = queryString.parse(search);
  // const [playTracks, setPlayTracks] = useState([]);
  const [playTrack, setPlayTrack] = useState([]);
  const lang = localStorage.getItem("lang");
  const [isReadyVideoForControls, setIsReadyVideoForControls] = useState(false);
  const [isLoadingVideo, setIsLoadingVideo] = useState(true);
  const [data, setData] = useState();

  const handleReady = () => {
    setIsReadyVideoForControls(true);
    setIsLoadingVideo(false);
  };

  const handleBuffer = () => {
    setIsLoadingVideo(true);
  };

  const { setIsCategoryOpen, setIsTypeOpen, setIsLangOpen } =
    useContext(CommonContext);
  // const [windowWidth, setWindowWidth] = useState("100%");
  // const [windowHeight, setWindowHeight] = useState("100%");

  const [videoState, setVideoState] = useState({
    playing: false,
    muted: false,
    volume: 0.4,
    playbackRate: 1.0,
    played: 0,
    seeking: false,
    buffer: true,
  });
  const [isFullScreen, setIsFullScreen] = useState(false);
  const elem = document.getElementById("thisVideo");

  useEffect(() => {
    console.log("isLoadingVideo test", isLoadingVideo);
    window.scrollTo(0, 0)
  }, [isLoadingVideo]);

  useEffect(() => {
    const uid = localStorage.getItem("uid");
    fetchData(uid);
  }, []);

  async function fetchData(uid) {
    setIsLoadingVideo(true);
    const response = await axios({
      method: "post",
      url: "https://digitalweb.bngrenew.com/api/dataApi",
      data: {
        uid: uid,
        msisdn: 701701701,
        mcc: 611,
        mnc: "04",
      },
      headers: config.get("headers"),
    });
    setData(response.data);
    setIsLoadingVideo(false);
    console.log(
      "api data response from fetchData function  -----> ",
      response.data
    );
  }

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    const givenTrackJsonInArr = data?.allVideos.filter((item) => item.id == id);
    // const restTracksJsonInArr = tracks.filter((item) => item.id != id);

    // console.log("givenTrackJsonInArr test", givenTrackJsonInArr);
    // console.log("restTracksJsonInArr test", restTracksJsonInArr);

    if (givenTrackJsonInArr && givenTrackJsonInArr.length > 0) {
      // const finalArr = givenTrackJsonInArr.concat(restTracksJsonInArr);
      // console.log("finalArr test", finalArr, typeof finalArr);
      // setPlayTracks(finalArr);
      // setPlayTracks([...givenTrackJsonInArr]);
      console.log(
        "givenTrackJsonInArr[0].videoSrc",
        givenTrackJsonInArr[0].videoSrc
      );
      setPlayTrack(givenTrackJsonInArr[0].videoSrc);
      window.scrollTo(0, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);

  useEffect(() => {
    document.onkeydown = function (event) {
      // eslint-disable-next-line default-case
      switch (event.keyCode) {
        case 32:
          event.preventDefault();
          if (videoState.playing) {
            setVideoState({ ...videoState, playing: false });
          } else if (!videoState.playing) {
            setVideoState({ ...videoState, playing: true });
          }
          break;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoState]);

  const handleFullScreenChange = () => {
    setIsFullScreen(document.fullscreenElement !== null);
  };
  const handleFullScreenClick = () => {
    if (!isFullScreen) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      }
    } else {
      document.exitFullscreen();
    }
  };

  //Destructuring the properties from the videoState
  const { playing, muted, volume, playbackRate, played, seeking } = videoState;

  const currentTime = videoPlayerRef.current
    ? videoPlayerRef.current.getCurrentTime()
    : "0:00";
  const duration = videoPlayerRef.current
    ? videoPlayerRef.current.getDuration()
    : "0:00";

  const formatCurrentTime = formatTime(currentTime);
  const formatDuration = formatTime(duration);

  const playPauseHandler = () => {
    //plays and pause the video (toggling)
    setVideoState({ ...videoState, playing: !videoState.playing });
  };

  const rewindHandler = () => {
    //Rewinds the video player reducing 5
    videoPlayerRef.current.seekTo(videoPlayerRef.current.getCurrentTime() - 2);
  };

  const handleFastFoward = () => {
    //FastFowards the video player by adding 10
    videoPlayerRef.current.seekTo(videoPlayerRef.current.getCurrentTime() + 2);
  };

  //console.log("========", (controlRef.current.style.visibility = "false"));
  const progressHandler = (state) => {
    console.log("state",state)
    if (count > 3) {
      console.log("close test");
      controlRef.current.style.visibility = "hidden"; // toggling player control container
    } else if (controlRef.current.style.visibility === "visible") {
      count += 1;
    }

    if (!seeking) {
      setVideoState({ ...videoState, ...state });
    }
  };

  const seekHandler = (e, value) => {
    setVideoState({ ...videoState, played: parseFloat(value / 100) });
    videoPlayerRef.current.seekTo(parseFloat(value / 100));
  };

  const seekMouseUpHandler = (e, value) => {
    console.log("console test in seekMouseUpHandler", value);

    setVideoState({ ...videoState, seeking: false });
    videoPlayerRef.current.seekTo(value / 100);
  };

  const volumeChangeHandler = (e, value) => {
    const newVolume = parseFloat(value) / 100;

    setVideoState({
      ...videoState,
      volume: newVolume,
      muted: Number(newVolume) === 0 ? true : false, // volume === 0 then muted
    });
  };

  const volumeSeekUpHandler = (e, value) => {
    const newVolume = parseFloat(value) / 100;

    setVideoState({
      ...videoState,
      volume: newVolume,
      muted: newVolume === 0 ? true : false,
    });
  };

  const muteHandler = () => {
    //Mutes the video player
    setVideoState({ ...videoState, muted: !videoState.muted });
  };

  const onSeekMouseDownHandler = (e) => {
    setVideoState({ ...videoState, seeking: true });
  };

  const mouseMoveHandler = () => {
    controlRef.current.style.visibility = "visible";
    count = 0;
  };

  const bufferStartHandler = () => {
    console.log("Bufering....... test");
    setVideoState({ ...videoState, buffer: true });
    // setIsLoadingVideo(true);
    handleBuffer();
    setIsReadyVideoForControls(false);
  };

  const bufferEndHandler = () => {
    console.log("buffering stoped ,,,,,,play test");
    setVideoState({ ...videoState, buffer: false });
    setIsReadyVideoForControls(true);
    setIsLoadingVideo(false);
  };

  const onProgress = (state)=>{
        
  }
  //   const handleFullScreen = () => {
  //     if (elem.requestFullscreen) {
  //       elem.requestFullscreen();
  //     }
  //     if (document.exitFullscreen) {
  //       document.exitFullscreen();
  //     }
  //   };
  const enterFullScreen = () => {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    }
  };

  return (
    <Fragment>
      <div className="invisible md:visible">
        <Navbar />
      </div>

      <div
        onClick={() => {
          setIsCategoryOpen(false);
          setIsTypeOpen(false);
          setIsLangOpen(false);
        }}
      >
        <div className="flex absolute justify-start md:hidden top-0 w-screen bg-yellow">
          <Link to="/home">
            <img className="h-4 m-4" alt="/" src={arrow3} />
          </Link>

          <Link to="/home">
            <img
              className="h-10 w-56 ml-5 rounded-full m-1 cursor-pointer"
              alt="/"
              src={chngimg.img2[lang]}
              onClick={() => {}}
            />
          </Link>
        </div>
        <div className="flex justify-center items-center w-full md:mt-20">
          {isLoadingVideo && (
            <div className="h-screen flex items-center justify-center">
              <div className="flex justify-center">
                <img src={loader} alt="/" className="float-center" />
              </div>
            </div>
          )}
        </div>

        <div className="flex justify-center">
          <div>{/* <h2>React player</h2> */}</div>

          {/* <Container> */}

          <div className="cursor-pointer md:mt-10 mt-20 md:w-2/3 md:h-2/3">
            <div
              className="player__wrapper"
              onMouseMove={mouseMoveHandler}
              onClick={playPauseHandler}
              id="thisVideo"
              onDoubleClick={handleFullScreenClick}
              // onMouseEnter={() => {
              //   setIsReadyVideoForControls(true);
              // }}
            >
              {/* {isLoadingVideo && (
                <div
                  className={` bg-yellow flex h-screen justify-center items-center text-white`}
                >
                  <img src={loader} alt="/" />
                </div>
              )} */}

              {playTrack && (
                <>
                  <ReactPlayer
                    ref={videoPlayerRef}
                    className={`player`}
                    url={playTrack}
                    onReady={handleReady}
                    width="100%"
                    height="100%"
                    playing={playing}
                    volume={volume}
                    muted={muted}
                    onProgress={progressHandler}
                    onBuffer={bufferStartHandler}
                    onBufferEnd={bufferEndHandler}
                    fullscreen={true}
                  />

                  {isReadyVideoForControls && playTrack && !isLoadingVideo && (
                    <Control
                      controlRef={controlRef}
                      onPlayPause={playPauseHandler}
                      playing={playing}
                      onRewind={rewindHandler}
                      onForward={handleFastFoward}
                      played={played}
                      onSeek={seekHandler}
                      onSeekMouseUp={seekMouseUpHandler}
                      volume={volume}
                      onVolumeChangeHandler={volumeChangeHandler}
                      onVolumeSeekUp={volumeSeekUpHandler}
                      mute={muted}
                      onMute={muteHandler}
                      playRate={playbackRate}
                      duration={formatDuration}
                      currentTime={formatCurrentTime}
                      onMouseSeekDown={onSeekMouseDownHandler}
                      theDivId="thisVideo"
                      enterFullScreen={enterFullScreen}
                      entereExitFullScreen={handleFullScreenClick}
                      isFullScreen={isFullScreen}
                      fullscreen={true}
                    />
                  )}
                </>
              )}
            </div>

            {/* {buffer && <p>Loading</p>} */}
          </div>

          {/* </Container> */}
        </div>
        {!isLoadingVideo && (
          <div className="flex md:justify-center flex-wrap mt-10">
            <div className="md:grid  md:grid-cols-3 md:gap-3 flex flex-wrap justify-center ">
              {data?.allVideos.map((itm) => {
                if (itm.lang === lang) {
                return (
                  <div className=" cursor-pointer">
                    <div className="cursor-pointer ">
                      <img
                        className="h-36 md:h-64 w-36 md:w-64  rounded-xl  cursor-pointer mx-2 md:mx-5"
                        alt="/"
                        src={itm.image}
                        onClick={() => {
                          if (playTrack !== itm.videoSrc) {
                            setPlayTrack(itm.videoSrc);
                            setIsReadyVideoForControls(false);
                            handleBuffer();
                          }
                        }}
                      />
                      <div className="flex justify-center">
                        <p className="text-gray-800 cursor-pointer text-lg font-semibold">
                          {itm[lang]}
                        </p>
                      </div>
                    </div>
                  </div>
                );
                }
              })}
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};
