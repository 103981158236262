import React, { useContext } from "react";
import about from "../assets/web_assets/about.png";
import Navbar from "./Navbar";
import Footer from "./Footer";
// import Logo from "../assets/Logo.png";
import arrow3 from "../assets/arrow3.png";
import { Link } from "react-router-dom";
import { navtxt } from "./text.js";
import { chngimg } from "./imagechng.js";
import { CommonContext } from "../Contexts/CommonStates";

const About = () => {
  const lang = localStorage.getItem("lang");
  const { setIsCategoryOpen, setIsTypeOpen, setIsLangOpen } =
    useContext(CommonContext);

  return (
    <div className="md:max-h-screen w-screen ">
      <div className="invisible md:visible">
        <Navbar />
      </div>

      <div
        onClick={() => {
          setIsCategoryOpen(false);
          setIsTypeOpen(false);
          setIsLangOpen(false);
        }}
      >
        <div className="flex absolute justify-start md:hidden top-0 w-screen bg-yellow">
          <Link to="/home">
            <img className="h-4 m-4" alt="/" src={arrow3} />
          </Link>
          <Link to="/home">
          <img
            className="h-10 w-56 ml-5 rounded-full m-1 cursor-pointer"
            alt="/"
            src={chngimg.img2[lang]}
            onClick={() => {}}
          /></Link>
        </div>

        <div className="md:hidden flex justify-center mt-4 text-lg font-bold">
          {navtxt.about[lang]}
        </div>
        <div className="flex flex-col md:flex-row mt-10 md:mt-12">
          <img
            src={about}
            alt="/"
            className=" md:w-3/7 md:h-1/2 md:ml-10 md:pt-16 w-[640px] md:pb-5"
          ></img>
          {/** Text part of About us- START */}
          <div className="flex flex-col md:ml-[50px] mt-4 p-4 pb-12">
            <div className="pt-8 ml-4">
              <h1 className="font-bold text-xl md:text-4xl tracking-tight">
                {navtxt.welcometo[lang]}
                <span className="text-yellow text-xl md:text-4xl tracking-tight">
                  {navtxt.edportal[lang]}
                </span>
              </h1>
             </div>
            <div className="pt-8 mb-10">
              <p className=" md:leading-8 md:font-semibold">
                {navtxt.p1[lang]}
              </p>

              <p className="  md:leading-8 md:font-semibold">
                {navtxt.p2[lang]}
              </p>
            </div>
          </div>
          {/** Text part of About us- END */}
        </div>
        <Footer />
      </div>
    </div>
  );
};
export default About;
