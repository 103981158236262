// import React from 'react';

export const navtxt = {
  home: {
    en: "Home",
    fa: "صفحه اصلی",
    ps: "اصلی پاڼه",
  },
  about: {
    en: "About Us",
    fa: "درباره ما",
    ps: "زموږ په اړه",
  },
  catg: {
    en: "Category",
    fa: "دسته بندی",
    ps: "کټګوري",
  },
  type: {
    en: "Type",
    fa: "انواع",
    ps: "ډول",
  },
  lang: {
    en: "Language",
    fa: "زبان",
    ps: "ژبه",
  },
  eng: {
    en: "English",
    fa: "انگلیسی",
    ps: "انګلیسي",
  },
  dari: {
    en: "Dari",
    fa: "دری",
    ps: "دري",
  },
  pashto: {
    en: "Pashto",
    fa: "پشتو",
    ps: "پښتو",
  },
  trendingitems: {
    en: "Trending Items",
    fa: "مورد پرطرفدار",
    ps: "غوره شوي توکي",
  },
  aboutdata: {
    en: "The MTN Education Portal is an online store that aims to support a healthy culture. The MTN Education Portal is a place where the author can communicate with the readers and we get to know the author.Where children get involved in the excitement of reading that can follow them for a lifetime and be useful for a beneficial society. The book provides you with digital access to books, videos, audiobooks, notes, and other useful items. which enriches your sense of curiosity and modern culture.We are committed to providing you with the best useful and useful resources through the booking window.",
    fa: "به دریچه آموزشی ام تی ان خوش آمدید: دریچه آموزشی ام تی ان یک منبع انترنتی بوده که هدف آن حمایت از یک فرهنگ سالم، معرفت و مطالعه میباشد. دریچه آموزشی ام تی ان مکانی است که نویسنده گان می توانند با خوانندگان ارتباط قایم نمایند و ما با نویسنده آشنا شویم. جایی که کودکان هم می توانند با مطالعه کتاب های سودمند و هیجانی به جامعه مفيد ببارآیند. خواندن وآموزش میتواند یک عمر ادامه داشته باشد و برای یک جامعه سودمند، از آنجایکه دسترسی دیجیتالی به کتاب ها، ویدیوها، کتاب های صوتی، یادداشت ها و دیگر موارد مفید ضروری است این خدمت برای شما همه این را فراهم میکند. بنآ این ىريچه میتواند باعث غنا مندی، حس کنجکاوی و فرهنگ مدرن شما درجامعه گردد.   ما متعهد هستیم تا بهترین منابع مفید وسود مند را از طریق دریچه آموزشی ام تی ان برای شما ارایه نماییم",
    ps: "د ام ټی ان تعلیمی پورټل ته ښه راغلاست: د ام ټی ان تعلیمی پورټل یو آنلاین سرچینه ده چې هدف یې د سالم کلتور ملاتړ، پوهه او اړینه مطالعه ده. د ام ټی ان تعلیمی پورټل هغه تګ لاره دی چې لیکوالان کولای شي له لوستونکو سره اړیکه ټینګه کړي او مونږ لیکوالان وپیژنو،او هغه ځای کی چی ماشومان کولای شی مطالعه وکړی او ټولنی ته ګټور او سو دمند وړاندی شی، همدا راز دوی کولای شی چی کتابونو، ویډیوګانو او غږيزه کتابونو ته ډیجیټلی لاسرسی ولري. د ام ټی ان تعلیمی پورټل ستاسو لپاره عصری او ګټورکتابونه ، ویډیوګانی ، غږيزه کتابونه او نورګټورتعلیمی موارد وړاندی کوی. مونږ ژمن یو چې تاسو ته د ام تی ان تعلیمی پورټل له لارې غوره او ګټورې سرچینې چمتو کړو.",
  },
  welcometxt: {
    en: "Welcome to MTN Education portal",
    fa: "به پورتال آموزشی ام تی ان خوش آمدید",
    ps: "د MTN تعلیمي پورټل ته ښه راغلاست",
  },
  allbooks: {
    en: "All Books",
    fa: "تمام کتاب ها",
    ps: "ټول کتابونه",
  },
  welcometo: {
    en: "Welcome To ",
    fa: "خوش آمدید به ",
    ps: "ښه راغلاست ",
  },
  edportal: {
    en: "MTN Education Portal",
    fa: "دریچه آموزشی ام تی ان",
    ps: "ام ټي ان تعلیمي",
  },
  p1: {
    en: "The MTN Education Portal is an online store that aims to support a healthy culture. The MTN Education Portal is a place where the author can communicate with the readers and we get to know the author.",
    fa: "پورتال آموزش و پرورش MTN فروشگاه آنلاین است که با هدف حمایت از فرهنگ سالم. پورتال آموزش و پرورش MTN است جایی که نویسنده می تواند با خوانندگان ارتباط برقرار و ما را به دانستن نویسنده.",
    ps: "د ایم ټی ان د زده کړې پرابلم یو آنلاین پلورنځی دی چې موخه یې د یو سالم کلتور ملاتړ دی. د MTN د زده کړې پورټ یو ځای دی چرته چې لیکوال د لوستونکو سره اړیکې کولی شی او مونږ د لیکوال سره پیژنو.",
  },
  p2: {
    en: "Where children get involved in the excitement of reading that can follow them for a lifetime and be useful for a beneficial society. The book provides you with digital access to books,videos, audiobooks, notes, and other useful items. which enriches your sense of curiosity and modern culture.We are committed to providing you with the best useful and useful resources through the booking window.",
    fa: "که در آن کودکان در هیجان خواندن است که می تواند آنها را برای یک عمر دنبال و مفید برای درگیر جامعه مفید. این کتاب شما را با دسترسی دیجیتال به کتاب ها، فیلم ها، کتاب های صوتی، یادداشت ها، و دیگر فراهم می کند موارد مفید. که حس کنجکاوی و فرهنگ مدرن شما را غنی می کند.ما متعهد به ارائه بهترین منابع مفید و مفید از طریق پنجره رزرو به شما.",
    ps: "چیرته چې ماشومان د لوست په هیجان کې لاس لری کوم چې کولی شی د یو ژوند لپاره دوی تعقیب کړی او د ګټورې ټولنې لپاره ګټور وی. کتاب تاسو ته د ډیجیټل لاسرسی چمتو کوی د کتابونو، ویډیوګانو، غږیز کتابونو، نوټ، او نورو ګټورو توکو ته. کوم چې ستاسو د حس حس د حس او عصری کلتور بډایه کوی. مونږ ژمن یو چې تاسو ته د کتاب کولو کړکۍ له لارې غوره ګټورې او ګټورې سرچینې برابرې کړو.",
  },
  unsubscribe: {
    en: "Unsubscribe",
    fa: "لغو اشتراک",
    ps: "د گډون لغوه",
  },
  unsubtext: {
    en: "Are you sure want to proceed?",
    fa: "آیا مطمئن هستید که می خواهید ادامه دهید؟",
    ps: "ایا تاسو ډاډه یاست چې مخ په وړاندې لاړ شئ؟",
  },
  no: {
    en: "No",
    fa: "نخیر",
    ps: "نه",
  },
  yes: {
    en: "Yes",
    fa: "بلی",
    ps: "هو",
  },
  ok: {
    en: "Ok",
    fa: "درست است",
    ps: "سمه ده",
  },
  Books: {
    en: "Books",
    fa: "کتاب ها",
    ps: "کتابونه",
  },
  Videos: {
    en: "Videos",
    fa: "ویدیو",
    ps: "ویډیو",
  },
  Audios: {
    en: "Audios",
    fa: "صوتی",
    ps: "غږیزه",
  },
  viewmore: {
    en:"View More",
    fa:"مشاهده بیشتر",
    ps:"نور وګوره"
  },
  comingsoon: {
    en:"Coming Soon",
    fa:"به زودی",
    ps:"ژر راځي"
  },
};
