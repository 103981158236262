import React, { useContext } from "react";
import { CommonContext } from "../Contexts/CommonStates";

const Button = ({ buttonName, className, entireElement }) => {
  const { currentSelectedCategory, setCurrentSelectedCategory, showBooksFun } =
    useContext(CommonContext);
  // const lang = localStorage.getItem("lang");

  return (
    <div>
      <div className="flex justify-center pt-4">
        <button
          className={
            className
              ? className
              : entireElement.en === currentSelectedCategory
              ? "btn btn-blue font-semibold py-1 px-2 md:w-[300px] md:py-3 md:px-4 rounded border bg-yellow text-white uppercase font-roboto md:ml-1 shadow-lg "
              : "btn btn-blue font-semibold py-1 px-2 md:py-3 md:w-[300px] md:px-3 rounded border bg-white text-gray-800 uppercase font-roboto md:ml-1 shadow-lg"
          }
          onClick={() => {
            showBooksFun(4);
            localStorage.setItem("currentCategory", entireElement.en);
            setCurrentSelectedCategory(entireElement.en);
          }}
        >
          {buttonName}
        </button>
      </div>
    </div>
  );
};
export default Button;
