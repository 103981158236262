import React, { useContext, useState, useEffect } from "react";
// import Logo from "../assets/Logo.png";
import list from "./list.json";
import type from "./types.json";
import language from "./language.json";
// import adventure from "./adventure.json";
import arrow from "../assets/arrow.png";
import arrow4 from "../assets/arrow4.png";
import Iconuser from "../assets/Iconuser.png";
import { navtxt } from "./text.js";
import { Link, useLocation } from "react-router-dom";
import { CommonContext } from "../Contexts/CommonStates";
import { chngimg } from "./imagechng.js";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import { Tooltip as ReactTooltip } from "react-tooltip";
import config from "./config";
import axios from "axios";

// import { reactLocalStorage } from 'reactjs-localstorage'

const Navbar = ({ suburl, setIsOpen }) => {
  const loc = useLocation();
  const {
    isCategoryOpen,
    setIsCategoryOpen,
    setCurrentSelectedCategory,
    isTypeOpen,
    setIsTypeOpen,
    isLangOpen,
    setIsLangOpen,
    setCurrentSelectedType,
    currentSelectedType,
    currentSelectedCategory,
    languageArr,
    setLanguageArr,
    showBooksFun,
    selectedInfo,
    setSelectedInfo,
    allCategoriesBooks,
    allBooks,
    setAllBooks,
  } = useContext(CommonContext);
  //   console.log(suburl);
  const lang = localStorage.getItem("lang");
  const [isDrop, setIsDrop] = useState(false);
  const [sidebar, SetSidebar] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(false);
  // const [data, setData] = useState();
  // const [data2, setData2] = useState();

  // const[lang,setLang]=useState();

  const changeLanguage = (ln) => {
    // console.log("changeLanguage",lang);
    localStorage.setItem("lang", ln);
    // window.location.reload();
  };

  // <ReactTooltip
  //       anchorId="unsub"
  //       place="bottom"
  //       content="Unsubscribe"
  //     />

  // useEffect(() => {
  //   const uid = localStorage.getItem("uid");
  //   // fetchData(uid);
  //   // fetchAllData(uid);
  // }, []);

  // async function fetchData(uid) {
  //   const response = await axios({
  //     method: "post",
  //     url: "https://digitalwebsandboxv1.bngrenew.com/api/categoryApi",
  //     data: {
  //       uid: uid,
  //       msisdn: 701701701,
  //       mcc: 611,
  //       mnc: "04",
  //     },
  //     headers: config.get("headers"),
  //   });
  //   if (response.data && Object.keys(response.data).length > 0) {
  //     setData(response.data);
  //     if (lang === "en") {
  //       setCurrentSelectedCategory(response.data.categoryEn[0].en);
  //     }
  //     if (lang === "fa") {
  //       setCurrentSelectedCategory(response.data.categoryFa[0].en);
  //     }
  //     if (lang === "ps") {
  //       setCurrentSelectedCategory(response.data.categoryPs[0].en);
  //     }
  //   }
  // }

  // useEffect(() => {
  //   if (data && data.length > 0) {
  //     if (lang === "en") {
  //       setCurrentSelectedCategory(data.categoryEn[0].en);
  //     }
  //     if (lang === "fa") {
  //       setCurrentSelectedCategory(data.categoryFa[0].en);
  //     }
  //     if (lang === "ps") {
  //       setCurrentSelectedCategory(data.categoryPs[0].en);
  //     }
  //   }
  // }, [lang]);

  // async function fetchAllData(uid) {
  //   const response = await axios({
  //     method: "post",
  //     url: "https://digitalwebsandboxv1.bngrenew.com/api/dataApi",
  //     data: {
  //       uid: uid,
  //       msisdn: 701701701,
  //       mcc: 611,
  //       mnc: "04",
  //     },
  //     headers: config.get("headers"),
  //   });
  //   setData2(response.data);
  // }

  useEffect(() => {
    let selectedInformation =
      allBooks &&
      Object.keys(allBooks).length > 0 &&
      allBooks.allBooks.filter((item) => {
        // console.log("item in navbar for selected info", item);
        if (item.categoryName === currentSelectedCategory) {
          // console.log("item if matched with the selected category", item);
          return item;
        } else {
          return !item;
        }
      });
    // console.log("selectedInformation", selectedInformation);
    setSelectedInfo(selectedInformation);
  }, [allCategoriesBooks, currentSelectedCategory]);

  useEffect(() => {
    if (
      currentSelectedType === "Videos" ||
      localStorage.getItem("type") === "Videos"
    ) {
      setLanguageArr([
        {
          lang: {
            en: "English",
            fa: "انگلیسی",
            ps: "انګلیسي",
          },
          id: "en",
        },
        {
          lang: {
            en: "Dari",
            fa: "دری",
            ps: "دري",
          },
          id: "fa",
        },
        {
          lang: {
            en: "Pashto",
            fa: "پشتو",
            ps: "پښتو",
          },
          id: "ps",
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedType]);

  return (
    <div
      className="flex justify-evenly pb-1 bg-yellow max-w-screen h-12 select-none fixed top-0 w-full md:z-10"
      onClick={() => {
        setIsCategoryOpen(false);
        setIsTypeOpen(false);
        setIsLangOpen(false);
      }}
    >
      {/* fixed top-0 w-full z-10 */}
      <div className="flex absolute left-2">
        <Link to="/home">
          <img
            className="h-10 w-56 rounded-full m-1 cursor-pointer"
            alt="/"
            src={chngimg.img2[lang]}
            onClick={() => {}}
          />
        </Link>
      </div>
      {!showCloseButton ? (
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          onClick={() => {
            SetSidebar((value) => !value);
            setShowCloseButton(true);
          }}
          className="inline-flex items-center p-2  absolute right-2   text-sm text-black rounded-lg md:hidden focus:outline-none  "
          aria-controls="navbar-default"
          aria-expanded="false"
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      ) : (
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          onClick={() => {
            SetSidebar((value) => !value);
            setShowCloseButton(false);
          }}
          className="inline-flex items-center p-2 font-black top-1 absolute right-2  text-sm text-black rounded-lg md:hidden focus:outline-none"
          aria-controls="navbar-default"
          aria-expanded="false"
        >
          X
        </button>
      )}
      {sidebar && (
        <div className="md:hidden py-4 z-80 fixed w-full flex flex-col overflow-y-auto top-12 bg-yellow">
          <div className=" font-bold font-roboto text-black py-2  bg-yellow pl-2 my-2">
            <div
              onClick={() => {
                SetSidebar((value) => !value);
                setShowCloseButton(false);
              }}
            >
              {navtxt.home[lang]}
            </div>
          </div>
          <div className="font-bold font-roboto text-black py-2 bg-yellow pl-2 my-2">
            {/* onClick={() => window.open("About.html", "_self")} */}
            <Link to="/about">{navtxt.about[lang]}</Link>
          </div>
          {currentSelectedType === "Books" && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                setIsTypeOpen(false);
                setIsLangOpen(false);
                setIsCategoryOpen((prev) => !prev);
              }}
              className=" font-bold flex justify-between font-roboto text-black py-2 bg-yellow pl-2 my-2"
            >
              {navtxt.catg[lang]}
              {isTypeOpen ? (
                <img src={arrow4} alt="/" className="h-2 w-2 mr-4 mt-2" />
              ) : (
                <img src={arrow} alt="/" className="h-2 w-2 mr-4 mt-2" />
              )}
            </div>
          )}
          {isCategoryOpen && (
            <div className="h-60 overflow-scroll  w-[90%] rounded-md ml-4 bg-yellow-light">
              {lang === "en" &&
                allCategoriesBooks?.categoryEn.map((item, i) => (
                  <div
                    className="mt-2 w-auto"
                    key={i}
                    onClick={() => {
                      SetSidebar((value) => !value);
                      setShowCloseButton(false);
                      // setCurrentSelectedCategory("All Books");
                    }}
                  >
                    <h3
                      onMouseEnter={() => setIsDrop((prev) => !prev)}
                      onMouseLeave={() => setIsDrop(false)}
                      onClick={() => {
                        localStorage.setItem("currentCategory", item.en);
                        setCurrentSelectedCategory(item.en);
                        showBooksFun(4);
                        // onMouseLeave={() => setIsDrop(false)}
                      }}
                      className={`${
                        currentSelectedCategory === item.en
                          ? "bg-gray-100 text-black"
                          : "text-white"
                      } cursor-pointer font-roboto hover:bg-white hover:text-black w-inherit px-2`}
                      // className="cursor-pointer text-black font-roboto  w-inherit px-2 ml-3"
                    >
                      {item[lang]}
                    </h3>
                  </div>
                ))}
              {lang === "fa" &&
                allCategoriesBooks?.categoryFa.map((item, i) => (
                  <div
                    className="mt-5 w-auto"
                    key={i}
                    onClick={() => {
                      SetSidebar((value) => !value);
                      setShowCloseButton(false);
                      // setCurrentSelectedCategory("All Books");
                    }}
                  >
                    <h3
                      onMouseEnter={() => setIsDrop((prev) => !prev)}
                      onMouseLeave={() => setIsDrop(false)}
                      onClick={() => {
                        localStorage.setItem("currentCategory", item.en);
                        setCurrentSelectedCategory(item.en);
                        showBooksFun(4);
                        // onMouseLeave={() => setIsDrop(false)}
                      }}
                      className={`${
                        currentSelectedCategory === item.en
                          ? "bg-gray-100 text-black"
                          : "text-white"
                      } cursor-pointer font-roboto hover:bg-white hover:text-black py-2 w-inherit px-2`}
                      // className="cursor-pointer text-black font-roboto  w-inherit px-2 ml-3"
                    >
                      {item[lang]}
                    </h3>
                  </div>
                ))}
              {lang === "ps" &&
                allCategoriesBooks?.categoryPs.map((item, i) => (
                  <div
                    className="mt-5 w-auto"
                    key={i}
                    onClick={() => {
                      SetSidebar((value) => !value);
                      setShowCloseButton(false);
                      // setCurrentSelectedCategory("All Books");
                    }}
                  >
                    <h3
                      onMouseEnter={() => setIsDrop((prev) => !prev)}
                      onMouseLeave={() => setIsDrop(false)}
                      onClick={() => {
                        localStorage.setItem("currentCategory", item.en);

                        setCurrentSelectedCategory(item.en);
                        showBooksFun(4);
                        // onMouseLeave={() => setIsDrop(false)}
                      }}
                      className={`${
                        currentSelectedCategory === item.en
                          ? "bg-gray-100 text-black"
                          : "text-white"
                      } cursor-pointer font-roboto hover:bg-white hover:text-black py-2 w-inherit px-2`}
                      // className="cursor-pointer text-black font-roboto  w-inherit px-2 ml-3"
                    >
                      {item[lang]}
                    </h3>
                  </div>
                ))}
            </div>
          )}
          <div
            onClick={(e) => {
              e.stopPropagation();
              setIsCategoryOpen(false);
              setIsLangOpen(false);
              setIsTypeOpen((prev) => !prev);
            }}
            className=" font-bold flex justify-between font-roboto text-black py-2 bg-yellow pl-2 my-2"
          >
            {navtxt.type[lang]}
            {isTypeOpen ? (
              <img src={arrow4} alt="/" className="h-2 w-2 mr-4 mt-2" />
            ) : (
              <img src={arrow} alt="/" className="h-2 w-2 mr-4 mt-2" />
            )}
          </div>
          {isTypeOpen && (
            <div className="ml-4  w-[90%] rounded-md  bg-yellow-light">
              {type.map((item, i) => (
                <div
                  className="mt-2 w-auto"
                  key={i}
                  onClick={() => {
                    SetSidebar((value) => !value);
                    setShowCloseButton(false);
                  }}
                >
                  {/* <Link to="/home"> */}
                  <h3
                    onMouseEnter={() => setIsDrop((prev) => !prev)}
                    onMouseLeave={() => setIsDrop(false)}
                    className={`${
                      currentSelectedType === item.en
                        ? "bg-gray-100 text-black"
                        : "text-white"
                    } cursor-pointer font-roboto hover:bg-white hover:text-black w-inherit px-2`}
                    onClick={() => {
                      localStorage.setItem("type", item.en);
                      setCurrentSelectedType(localStorage.getItem("type"));
                      if (currentSelectedType !== "Books") {
                        if (lang === "en") {
                          localStorage.setItem(
                            "currentCategory",
                            allCategoriesBooks?.categoryEn[0].en
                          );
                          setCurrentSelectedCategory(
                            allCategoriesBooks?.categoryEn[0].en
                          );
                        }
                        if (lang === "fa") {
                          localStorage.setItem(
                            "currentCategory",
                            allCategoriesBooks?.categoryFa[0].en
                          );
                          setCurrentSelectedCategory(
                            allCategoriesBooks?.categoryFa[0].en
                          );
                        }
                        if (lang === "ps") {
                          localStorage.setItem(
                            "currentCategory",
                            allCategoriesBooks?.categoryPs[0].en
                          );
                          setCurrentSelectedCategory(
                            allCategoriesBooks?.categoryPs[0].en
                          );
                        }
                      }
                      if (item.en === "Videos") {
                        setLanguageArr([
                          {
                            lang: {
                              en: "Dari",
                              fa: "دری",
                              ps: "دري",
                            },
                            id: "fa",
                          },
                          {
                            lang: {
                              en: "Pashto",
                              fa: "پشتو",
                              ps: "پښتو",
                            },
                            id: "ps",
                          },
                        ]);
                        if (localStorage.getItem("lang") === "en") {
                          localStorage.setItem("lang", "fa");
                        }
                      } else {
                        setLanguageArr(language);
                      }
                    }}
                  >
                    {item[lang]}
                  </h3>
                  {/* </Link> */}
                </div>
              ))}
            </div>
          )}
          <div
            onClick={(e) => {
              e.stopPropagation();
              setIsCategoryOpen(false);
              setIsTypeOpen(false);
              setIsLangOpen((prev) => !prev);
            }}
            className="flex justify-between font-bold font-roboto text-black py-2 bg-yellow pl-2 my-2"
          >
            {navtxt.lang[lang]}
            {isLangOpen ? (
              <img src={arrow4} alt="/" className="h-2 w-2 mr-4 mt-2" />
            ) : (
              <img src={arrow} alt="/" className="h-2 w-2 mr-4 mt-2" />
            )}
          </div>
          {isLangOpen && (
            <div className="w-[90%] rounded-md  bg-yellow-light ml-4">
              {languageArr &&
                languageArr.map((item, k) => (
                  <div
                    className="mt-2 w-auto"
                    key={k}
                    onClick={() => {
                      changeLanguage(item.id);
                      showBooksFun(4);
                      SetSidebar((value) => !value);
                      setShowCloseButton(false);
                      if (currentSelectedType === "Books") {
                        // console.log("type is books");
                        if (item.id === "en") {
                          // console.log("lang en");
                          localStorage.setItem(
                            "currentCategory",
                            allCategoriesBooks?.categoryEn[0].en
                          );
                          setCurrentSelectedCategory(
                            allCategoriesBooks?.categoryEn[0].en
                          );
                        }
                        if (item.id === "fa") {
                          // console.log("lang fa");
                          localStorage.setItem(
                            "currentCategory",
                            allCategoriesBooks?.categoryFa[0].en
                          );
                          setCurrentSelectedCategory(
                            allCategoriesBooks?.categoryFa[0].en
                          );
                        }
                        if (item.id === "ps") {
                          // console.log("lang ps");
                          localStorage.setItem(
                            "currentCategory",
                            allCategoriesBooks?.categoryPs[0].en
                          );
                          setCurrentSelectedCategory(
                            allCategoriesBooks?.categoryPs[0].en
                          );
                        }
                      }
                    }}
                  >
                    <h3
                      onMouseEnter={() => setIsDrop((prev) => !prev)}
                      onMouseLeave={() => setIsDrop(false)}
                      className={`${
                        localStorage.getItem("lang") === item.id
                          ? "bg-gray-100 text-black"
                          : "text-white"
                      }  cursor-pointer font-roboto w-inherit hover:text-black hover:bg-white w-inherit px-2`}
                    >
                      {item.lang[lang]}
                    </h3>
                  </div>
                ))}
            </div>
          )}
          <div className="font-bold font-roboto text-black py-2 bg-yellow pl-2 my-2">
            <Link to="/unsub">{navtxt.unsubscribe[lang]}</Link>
          </div>
        </div>
      )}

      {/********************** Desktop view starts ******************/}

      <div className="hidden w-full md:block md:w-auto " id="navbar-default ">
        <ul className="flex justify-between items-center pt-2 mt-1  decoration-none ">
          <div className="mx-8">
            <li
              className={`hover:cursor-pointer flex justify-center font-semibold font-roboto h-9 ${
                loc.pathname === "/home" || loc.pathname === "/"
                  ? "border-gray-900 rounded border-b-4 text-white"
                  : "text-black"
              }`}
            >
              <Link to="/home" className="mb-1">
                {navtxt.home[lang]}
              </Link>
            </li>
          </div>
          <div className="mx-8">
            <li
              className={`hover:cursor-pointer font-semibold flex justify-center font-roboto h-9 ${
                loc.pathname === "/about"
                  ? "border-gray-900 rounded border-b-4 text-white"
                  : "text-black"
              }`}
            >
              {" "}
              {/*onClick={()=>window.open("About.html","_self")}*/}
              <Link to="/about" className="mb-1">
                {navtxt.about[lang]}
              </Link>
            </li>
          </div>
          {currentSelectedType === "Books" && (
            <div className="mx-8 relative">
              <li
                onClick={(e) => {
                  e.stopPropagation();
                  setIsTypeOpen(false);
                  setIsLangOpen(false);
                  setIsCategoryOpen((prev) => !prev);
                }}
                className="flex justify-center cursor-pointer font-semibold h-9 font-roboto"
              >
                {navtxt.catg[lang]}
                {isCategoryOpen ? (
                  <img src={arrow4} alt="/" className="h-2 w-2 ml-1 mt-2" />
                ) : (
                  <img src={arrow} alt="/" className="h-2 w-2 ml-1 mt-2" />
                )}
              </li>
              {isCategoryOpen && (
                <div className=" absolute bg-yellow  h-64 w-48 overflow-scroll no-scrollbar">
                  {lang === "en" &&
                    allCategoriesBooks?.categoryEn.map((item, i) => (
                      <Link to="/home">
                        <div className="mt-2 w-auto" key={i}>
                          <h3
                            onMouseEnter={() => setIsDrop((prev) => !prev)}
                            onMouseLeave={() => setIsDrop(false)}
                            onClick={() => {
                              localStorage.setItem("currentCategory", item.en);
                              setCurrentSelectedCategory(item.en);
                              showBooksFun(4);
                            }}
                            className={`${
                              currentSelectedCategory === item.en
                                ? "bg-gray-100 text-black"
                                : "text-white"
                            } cursor-pointer font-roboto hover:bg-white hover:text-black w-inherit px-2`}
                          >
                            {item[lang]}
                          </h3>
                        </div>
                      </Link>
                    ))}
                  {lang === "fa" &&
                    allCategoriesBooks?.categoryFa.map((item, i) => (
                      <Link to="/home">
                        <div className="mt-5 w-auto" key={i}>
                          <h3
                            onMouseEnter={() => setIsDrop((prev) => !prev)}
                            onMouseLeave={() => setIsDrop(false)}
                            onClick={() => {
                              localStorage.setItem("currentCategory", item.en);
                              setCurrentSelectedCategory(item.en);
                              showBooksFun(4);
                            }}
                            className={`${
                              currentSelectedCategory === item.en
                                ? "bg-gray-100 text-black"
                                : "text-white"
                            } cursor-pointer font-roboto hover:bg-white hover:text-black py-2 w-inherit px-2`}
                          >
                            {item[lang]}
                          </h3>
                        </div>
                      </Link>
                    ))}
                  {lang === "ps" &&
                    allCategoriesBooks?.categoryPs.map((item, i) => (
                      <Link to="/home">
                        <div className="mt-5 w-auto" key={i}>
                          <h3
                            onMouseEnter={() => setIsDrop((prev) => !prev)}
                            onMouseLeave={() => setIsDrop(false)}
                            onClick={() => {
                              localStorage.setItem("currentCategory", item.en);
                              setCurrentSelectedCategory(item.en);
                              showBooksFun(4);
                            }}
                            className={`${
                              currentSelectedCategory === item.en
                                ? "bg-gray-100 text-black"
                                : "text-white"
                            } cursor-pointer font-roboto hover:bg-white hover:text-black py-2 w-inherit px-2`}
                          >
                            {item[lang]}
                          </h3>
                        </div>
                      </Link>
                    ))}
                </div>
              )}
            </div>
          )}

          <div className="mx-8 relative">
            <li
              onClick={(e) => {
                e.stopPropagation();
                setIsCategoryOpen(false);
                setIsLangOpen(false);
                setIsTypeOpen((prev) => !prev);
              }}
              className="flex justify-center hover:cursor-pointer font-semibold font-roboto h-9"
            >
              {navtxt.type[lang]}
              {isTypeOpen ? (
                <img src={arrow4} alt="/" className="h-2 w-2 ml-1 mt-2" />
              ) : (
                <img src={arrow} alt="/" className="h-2 w-2 ml-1 mt-2" />
              )}
            </li>
            {isTypeOpen && (
              <div className="absolute bg-yellow h-auto w-28">
                {type.map((item, i) => (
                  <div className="mt-2 w-auto" key={i}>
                    <Link to="/home">
                      <h3
                        onMouseEnter={() => setIsDrop((prev) => !prev)}
                        onMouseLeave={() => setIsDrop(false)}
                        className={`${
                          currentSelectedType === item.en
                            ? "bg-gray-100 text-black"
                            : "text-white"
                        } cursor-pointer font-roboto hover:bg-white hover:text-black w-inherit px-2`}
                        onClick={() => {
                          localStorage.setItem("type", item.en);
                          setCurrentSelectedType(localStorage.getItem("type"));

                          if (currentSelectedType !== "Books") {
                            if (lang === "en") {
                              localStorage.setItem(
                                "currentCategory",
                                allCategoriesBooks?.categoryEn[0].en
                              );
                              setCurrentSelectedCategory(
                                allCategoriesBooks?.categoryEn[0].en
                              );
                            }
                            if (lang === "fa") {
                              localStorage.setItem(
                                "currentCategory",
                                allCategoriesBooks?.categoryFa[0].en
                              );
                              setCurrentSelectedCategory(
                                allCategoriesBooks?.categoryFa[0].en
                              );
                            }
                            if (lang === "ps") {
                              localStorage.setItem(
                                "currentCategory",
                                allCategoriesBooks?.categoryPs[0].en
                              );
                              setCurrentSelectedCategory(
                                allCategoriesBooks?.categoryPs[0].en
                              );
                            }
                          }
                          if (item.en === "Videos") {
                            setLanguageArr([
                              {
                                lang: {
                                  en: "Dari",
                                  fa: "دری",
                                  ps: "دري",
                                },
                                id: "fa",
                              },
                              {
                                lang: {
                                  en: "Pashto",
                                  fa: "پشتو",
                                  ps: "پښتو",
                                },
                                id: "ps",
                              },
                            ]);

                            if (localStorage.getItem("lang") === "en") {
                              localStorage.setItem("lang", "fa");
                            }
                          } else {
                            setLanguageArr(language);
                          }
                        }}
                      >
                        {item[lang]}
                      </h3>
                    </Link>
                  </div>
                ))}
              </div>
            )}
          </div>
        </ul>
      </div>
      <div
        className="hidden w-full md:block md:w-auto absolute right-2"
        id="navbar-default "
      >
        <div className=" flex justify-end mr-2">
          <h3
            className="m-2 mr-1 pr-2 font-semibold hover:cursor-pointer  flex font-roboto"
            onClick={(e) => {
              e.stopPropagation();
              setIsCategoryOpen(false);
              setIsTypeOpen(false);
              setIsLangOpen((prev) => !prev);
            }}
          >
            {navtxt.lang[lang]} {/** {isArrowOpen ? arrow :  arrow4} */}
            {isLangOpen ? (
              <img src={arrow4} alt="/" className="h-2 w-2 ml-1 mt-2" />
            ) : (
              <img src={arrow} alt="/" className="h-2 w-2 ml-1 mt-2" />
            )}
          </h3>
          {isLangOpen && (
            <div className=" absolute bg-yellow right-10 top-10 w-28">
              {languageArr &&
                languageArr.map((item, k) => (
                  <div
                    className="mt-2"
                    key={k}
                    onClick={() => {
                      changeLanguage(item.id);
                      showBooksFun(4);
                      if (currentSelectedType === "Books") {
                        // console.log("type is books");
                        if (item.id === "en") {
                          // console.log("lang en");
                          localStorage.setItem(
                            "currentCategory",
                            allCategoriesBooks?.categoryEn[0].en
                          );
                          setCurrentSelectedCategory(
                            allCategoriesBooks?.categoryEn[0].en
                          );
                        }
                        if (item.id === "fa") {
                          // console.log("lang fa");
                          localStorage.setItem(
                            "currentCategory",
                            allCategoriesBooks?.categoryFa[0].en
                          );
                          setCurrentSelectedCategory(
                            allCategoriesBooks?.categoryFa[0].en
                          );
                        }
                        if (item.id === "ps") {
                          // console.log("lang ps");
                          localStorage.setItem(
                            "currentCategory",
                            allCategoriesBooks?.categoryPs[0].en
                          );
                          setCurrentSelectedCategory(
                            allCategoriesBooks?.categoryPs[0].en
                          );
                        }
                      }
                    }}
                  >
                    <h3
                      onMouseEnter={() => setIsDrop((prev) => !prev)}
                      onMouseLeave={() => setIsDrop(false)}
                      className={`${
                        localStorage.getItem("lang") === item.id
                          ? "bg-gray-100 text-black h-inherit"
                          : "text-white"
                      }  cursor-pointer font-roboto w-inherit hover:text-black hover:bg-white w-inherit px-2`}
                    >
                      {item.lang[lang]}
                    </h3>
                  </div>
                ))}
            </div>
          )}
          <div
            className="cursor-pointer"
            id="unsub"
            onClick={() => setIsOpen(true)}
          >
            <img src={Iconuser} className="h-4 w-4 mt-3 mr-2" alt="/"></img>
          </div>
          <ReactTooltip
            anchorId="unsub"
            place="bottom"
            content={navtxt.unsubscribe[lang]}
            style={{
              backgroundColor: "white",
              color: "black",
              font: "roboto",
              border: "3px solid #FFCC00",
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default Navbar;
