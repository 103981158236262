import config from "react-global-configuration";
// const ln = localStorage.getItem("lang") ? localStorage.getItem("lang") : "en";
const ln = localStorage.getItem("lang");

config.set({
  // base: "https://digitalweb.bngrenew.com/api",
  base: "https://digitalweb.bngrenew.com/api",
  domain: "btc",
  he: "/he",
  subscribe: "/subscribe",
  requestOTP: "/requestOTP",
  activate: "/activate",
  checkSub: "/checkSub",
  unsubscribe: "/unsubscribe",
  userPacks: "/userPacks",
  appConfig: "/appConfig",
  // dataApi: "/dataApi",
  headers: {
    Username: "web",
    Servicefor: "mtnEduPortal",
    // "Language": "en",
    AllowedHeaders: "*",
    AllowedMethods: "GET",
    AllowedMethods: ["PUT","POST","DELETE"],
    ExposeHeaders: [],
    "Access-Control-Allow-Origin":"http://localhost:3000/",
    Language: ln,
    Reqfrom: "web",
  },
});

export default config;
